import React from "react";
import Button from "react-bootstrap/Button";
import Cal from "@calcom/embed-react";

function ThankYou(props) {
  const params = new URLSearchParams(props.location.search);

  if (params.has("displayText") && params.has("backButtonAction")) {
    const displayText = params.get("displayText");
    const backButtonAction = params.get("backButtonAction");
    return (
      <>
        <p>{displayText}</p>
        <p>
          <Button variant="primary" href={backButtonAction} className="button">
            Go Back
          </Button>
        </p>
      </>
    );
  } else {
    if (params.has("form")) {
      var form = params.get("form");
      var firstName = params.get("firstName");
      if (params.has("event")) {
        var event = params.get("event");
      }
      if (form === "longform-rfi") {
        return (
          <>
            <h3>
              {firstName}, thank you for your interest in Meridian University.
              An Admissions Advisor will reach out to you shortly with more
              information
            </h3>
            <p>
              If you are thinking about applying to Meridian, be sure to review
              the{" "}
              <a href="/admissions/graduate-admissions/admissions-overview">
                Admissions Overview
              </a>{" "}
              and the{" "}
              <a href="/admissions/graduate-admissions/application-process">
                Application Process
              </a>{" "}
              pages.
            </p>
            <p>
              Want to connect directly with program faculty? Sign up for an{" "}
              <a href="/admissions/graduate-admissions/info-sessions-and-events">
                Online Information Session
              </a>
              .
            </p>
            <p>
              Schedule a voice or video call with an Admissions Advisor. During
              the call you can ask questions about Meridian's academic
              structure, degree programs, and concentrations, as well as
              understand the admissions process and timeline.
            </p>
            <Cal
              calLink="forms/901960ce-d45e-4a65-8ba4-815761af3995"
              config={{ theme: "light" }}
            />
            <p>
              <Button
                variant="primary"
                href="javascript:history.go(-1)"
                className="button"
              >
                Go Back
              </Button>
            </p>
          </>
        );
      }
      if (form === "shortform-rfi") {
        return (
          <>
            <h3>
              {firstName}, thank you for your interest in Meridian University!
              We're thrilled to have you on board and to keep you informed about
              the exciting events and announcements happening here at Meridian.
            </h3>
            <p>
              If you are thinking about applying to Meridian, be sure to review
              the{" "}
              <a href="/admissions/graduate-admissions/admissions-overview">
                Admissions Overview
              </a>{" "}
              and the{" "}
              <a href="/admissions/graduate-admissions/application-process">
                Application Process
              </a>{" "}
              pages.
            </p>
            <p>
              Want to connect directly with program faculty? Sign up for an{" "}
              <a href="/admissions/graduate-admissions/info-sessions-and-events">
                Online Information Session
              </a>
              .
            </p>
            <p>
              Schedule a voice or video call with an Admissions Advisor. During
              the call you can ask questions about Meridian's academic
              structure, degree programs, and concentrations, as well as
              understand the admissions process and timeline.
            </p>
            <Cal
              calLink="forms/901960ce-d45e-4a65-8ba4-815761af3995"
              config={{ theme: "light" }}
            />
            <p>
              <Button
                variant="primary"
                href="javascript:history.go(-1)"
                className="button"
              >
                Go Back
              </Button>
            </p>
          </>
        );
      }
      if (form === "iv-podcast") {
        return (
          <>
            <h3>
              {firstName}, thank you for registering to receive updates about
              the Integral Voices podcast!
            </h3>
            <p>
              We will email when there are new Integral Voices podcast episodes
              available. In the meantime, you can listen to previous episodes on
              the{" "}
              <a href="{{base_url}}/public-programs/podcasts/integral-voices">
                Integral Voices Podcast Page
              </a>
            </p>
            <p>
              <Button
                variant="primary"
                href="javascript:history.go(-1)"
                className="button"
              >
                Go Back
              </Button>
            </p>
          </>
        );
      }
      if (form === "ipc-2021") {
        return (
          <>
            <h3>
              {firstName}, thank you for registering for more information on the
              upcoming Integral Practitioner Convergence!
            </h3>
            <p>We will email you with more details soon.</p>
            <p>
              <Button
                variant="primary"
                href="/public-programs/workshops-and-events/integral-practitioner-convergence-2021"
                className="button"
              >
                Go Back
              </Button>
            </p>
          </>
        );
      }
      if (form === "ipc-2021-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming 2021 Integral
              Practitioner Convergence!
            </h3>
            <p>
              You will receive your receipt shortly via email along with more
              information on what to expect as the April 17 &amp; 18 event
              approaches.
            </p>
            <p>
              <a
                href="/public-programs/workshops-and-events/integral-practitioner-convergence-2021"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "ipc-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming 2022 Integral
              Practitioner Convergence!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the May 6 - 8 event approaches.
            </p>
            <p>
              <a
                href="/public-programs/workshops-and-events/integral-practitioner-convergence-2022"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "uu-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Unfolding Understanding
              Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/unfolding-understanding"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "uu-fall-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Unfolding Understanding
              Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/unfolding-understanding-fall-2022"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "uu-winter-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Unfolding Understanding
              Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/unfolding-understanding-winter-2022"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "cette-2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Creative
              Entrepreneurship and the Transformation Economy Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/creative-entrepreneurship-and-the-transformation-economy"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "cette-fa2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Creative
              Entrepreneurship and the Transformation Economy Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/creative-entrepreneurship-and-the-transformation-economy-fa-2023"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "psychedelics-fa2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming The Road from Eleusis:
              Psychedelics, Trauma Healing, and the Neuroscience of
              Transformative Learning Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/psychedelics-trauma-healing-and-the-neuroscience-of-transformative-learning-fa-2023"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "dp-winter-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Reimagining the Future
              of Developmental Power Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/developmental-power-winter-2022"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "dc-spring-2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Developmental Coaching
              Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/developmental-coaching-spring-2023"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "mpw-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Making Possible Worlds
              Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/making-possible-worlds"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "polypoetic-fall-2022-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Interactig with the
              Polypoetic Psyche Online Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/interacting-with-the-polypoetic-psyche"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "tcdt-summer-2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Transforming Collective
              and Developmental Trauma Summer Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/transfoming-collective-and-developmental-trauma-su-2023"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "pp-summer-2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Purposeful Pathways
              Summer Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/purposeful-pathways-su-2023"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "purpose-fa2023-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Purposeful Pathways
              Summer Course!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/purposeful-pathways-fa-2023"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "toc-tll-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming{" "}
              {event === "berlin"
                ? "Berlin"
                : event === "cairo"
                ? "Cairo"
                : event === "istanbul"
                ? "Instanbul"
                : event === "johannesburg"
                ? "Johannesburg"
                : event === "prague"
                ? "Prague"
                : event === "la"
                ? "Los Angeles"
                : null}{" "}
              Transformative Learning Lab!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/transformative-learning-labs"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "integral-healing-wi2024-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Integral Healing: A Multi-Dimensional Approach to Well-Being!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/integral-healing-a-multi-dimensional-approach-to-well-being-winter-2024"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      }
      if (form === "participatory-approaches-sp2025-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Participatory Approaches to Facilitation!
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/participatory-approaches-to-facilitation-spring-2025"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        )
      }
      if (form === "growing-professionally-sp2024-purchase") {
        return (
          <>
            <h3>
              Thank you for registering for the upcoming Growing Professionally
              Together: Transformative Communities of Practice as Developmental
              Spaces! Open Enrollment Online Course
            </h3>
            <p>
              You will receive an email shortly with more information on what to
              expect as the course approaches.
            </p>
            <p>
              <a
                href="/public-programs/online-courses/growing-together-professionally-sp-2024"
                className="button"
              >
                Go Back
              </a>
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>Thank you for your submission.</p>
          </>
        );
      }
    } else {
      return (
        <>
          <p>Thank you for your submission.</p>
        </>
      );
    }
  }
}

export default ThankYou;
